export default {
  computed: {
    isInValidName() {
      let valid = true;
      if (!this.user.name) {
        valid = false;
      }
      return valid;
    },
    isInValidEmail() {
      const regex = new RegExp(
        /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
      );
      return regex.test(this.user.mail);
    },
    isInValidTelnumber() {
      const regex = new RegExp(/^\d+$/);
      return regex.test(this.user.tel_number);
    },
    isInValidNumCount() {
      const regex = new RegExp(/^.{8}$/);
      return regex.test(this.user.tel_number);
    },
    isInValidPass() {
      const regex = new RegExp(/^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,}$/);
      return regex.test(this.user.pass);
    },
    isInValidPassConfirm() {
      if (this.user.pass !== this.user.passConfirm) {
        return false;
      } else {
        return true;
      }
    },
  },
};
