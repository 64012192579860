<template>
  <div>
    <div class="modal_inner modal_inner--01">
      <div class="content03 content03--modal">
        <h2>パスワードの変更</h2>
        <div class="wrap">
          <form action="">
            <dl>
              <dt>現在のパスワード</dt>
              <dd>
                <input
                  type="text"
                  class="pass"
                  id="pass01"
                  v-model="user.old_pass"
                />
              </dd>
            </dl>
            <dl>
              <dt>新しいパスワード</dt>
              <dd>
                <input
                  type="text"
                  class="pass"
                  id="pass02"
                  v-model="user.pass"
                />
                <p class="note_02">
                  <span class="sub_txt"
                    >※半角のアルファベットと数字の組合せで8文字以上でご登録下さい。例:Mog012345678</span
                  ><br />
                  <span v-if="valid.pass" class="note"
                    >パスワードは半角英数字8文字以上で入力してください</span
                  >
                </p>
              </dd>
            </dl>
            <dl>
              <dt>新しいパスワード（確認用）</dt>
              <dd>
                <input
                  type="password"
                  class="pass"
                  id="pass03"
                  v-model="user.passConfirm"
                />
                <span v-if="valid.passConfirm" class="note"
                  >パスワードが一致しません</span
                >
              </dd>
            </dl>
            <p class="submit">
              <input
                type="submit"
                value="変更する"
                v-on:click.prevent="changePass()"
              />
            </p>
          </form>
          <button class="btn_modal_close" @click="$emit('close')">
            <img
              src="../assets/images/parts/ico_close.svg"
              width=""
              height=""
              alt="閉じる"
            />閉じる
          </button>
        </div>
        <button class="btn_modal_close_right" @click="$emit('close')">
          <img
            src="../assets/images/parts/ico_close.svg"
            width=""
            height=""
            alt="閉じる"
          />
        </button>
      </div>
    </div>
    <div class="modal_outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
import Valid from "@/mixins/validate";
export default {
  name: "modalPassword",
  data: function () {
    return {
      user: {},
      valid: {},
      result: {},
    };
  },
  mixins: [Valid],
  methods: {
    changePass() {
      if (this.isInValidPass && this.isInValidPassConfirm) {
        this.$store.dispatch("changePassword", this.user).then((response) => {
          if (response.data.result) {
            alert("パスワードを変更しました");
            this.$emit("close");
          } else {
            alert("パスワードが正しくありません");
          }
        });
      }
      !this.isInValidPass
        ? (this.valid.pass = true)
        : (this.valid.pass = false);
      !this.isInValidPassConfirm
        ? (this.valid.passConfirm = true)
        : (this.valid.passConfirm = false);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span.note {
  font-size: 12px;
  color: rgb(255, 0, 0);
}
</style>
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_form_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_mypage.css" scoped></style>
