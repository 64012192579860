<template>
  <div>
    <div class="modal_inner modal_inner--01">
      <div class="content03 content03--modal mail">
        <h2>電話投票番号の登録</h2>
        <div class="wrap">
          <form action="">
            <dl>
              <dt>新しい電話投票番号</dt>
              <dd>
                <input type="text" v-model="user.tel_number" />
              </dd>
            </dl>
            <p class="submit">
              <input
                type="submit"
                value="変更する"
                v-on:click.prevent="changeTelnumber()"
              />
            </p>
          </form>
          <button class="btn_modal_close" @click="$emit('close')">
            <img
              src="../assets/images/parts/ico_close.svg"
              width=""
              height=""
              alt="閉じる"
            />閉じる
          </button>
        </div>
        <button class="btn_modal_close_right" @click="$emit('close')">
          <img
            src="../assets/images/parts/ico_close.svg"
            width=""
            height=""
            alt="閉じる"
          />
        </button>
      </div>
    </div>
    <div class="modal_outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
import Valid from "@/mixins/validate";
export default {
  name: "modalTelnumber",
  data: function () {
    return {
      user: {},
      valid: {},
      result: {},
    };
  },
  mixins: [Valid],
  methods: {
    changeTelnumber() {
      if (this.isInValidTelnumber) {
        if (this.isInValidNumCount) {
          this.$store
            .dispatch("changeTelnumber", this.user)
            .then((response) => {
              if (response.data.result) {
                alert("情報を変更しました");
                this.$emit("close");
              }
            });
        } else {
          alert("8桁で入力してください");
        }
      } else {
        alert("半角数字で入力してください");
      }
      !this.isInValidTelnumber
        ? (this.valid.tel_number = true)
        : (this.valid.tel_number = false);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span.note {
  font-size: 12px;
  color: rgb(255, 0, 0);
}
</style>
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_form_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_mypage.css" scoped></style>
