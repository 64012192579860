<template>
  <section class="bg_color">
    <h2>基本情報</h2>
    <div class="box_basic">
      <dl class="lst_basic">
        <dt>メールアドレス</dt>
        <dd class="mail">{{ info.mail }}</dd>
        <dd class="btnarea">
          <button
            class="btn_pass"
            @click="isShowChangeMail = !isShowChangeMail"
          >
            変更
          </button>
        </dd>
      </dl>
      <dl class="lst_basic">
        <dt>パスワード</dt>
        <dd class="pass">
          <input type="password" v-model="pass" v-if="!visible" /><input
            type="text"
            v-model="pass"
            v-if="visible"
          /><span id="buttonEye" class="fa fa-eye" v-on:click="eyeClick"></span>
        </dd>
        <dd class="btnarea">
          <button class="btn_pass" @click="isShowPassword = !isShowPassword">
            変更
          </button>
        </dd>
      </dl>
      <dl class="lst_basic">
        <dt>電話投票番号</dt>
        <dd class="tel">
          <span v-if="info.tel_number">{{ info.tel_number }}</span>
          <span v-else>未登録</span>
        </dd>
        <dd class="btnarea">
          <button class="btn_pass" @click="isShowTelnumber = !isShowTelnumber">
            変更
          </button>
        </dd>
      </dl>
      <dl class="lst_basic">
        <dt>メールマガジン</dt>
        <dd class="pass" v-if="info.mail_recive_flg === '0'">受け取らない</dd>
        <dd class="pass" v-if="info.mail_recive_flg === '1'">受け取る</dd>
        <dd class="btnarea">
          <button class="btn_pass" @click="isShowMagazine = !isShowMagazine">
            変更
          </button>
        </dd>
      </dl>
      <dl class="lst_basic">
        <dt>登録 / 入会日</dt>
        <dd class="date">{{ moment(info.created_date) }}</dd>
      </dl>
    </div>
    <div class="box_lnk">
      <router-link
        to="/inquiry"
        v-on:click="this.$clearAllIntervals()"
        class="btn_pass"
        >お問い合わせはこちら</router-link
      >
      <button
        class="btn_withdrawal"
        @click="isShowWithdrawal = !isShowWithdrawal"
      >
        退会はこちら
      </button>
    </div>
    <transition name="fade">
      <modalChangeMail v-show="isShowChangeMail" @close="closeModalMail" />
    </transition>
    <transition name="fade">
      <modalPassword v-show="isShowPassword" @close="closeModalPassword" />
    </transition>
    <transition name="fade">
      <modalChangeTelnumber
        v-show="isShowTelnumber"
        @close="closeModalTelnumber"
      />
    </transition>
    <transition name="fade">
      <modalMagazine
        v-show="isShowMagazine"
        @close="closeMagazine"
        :magazineStatus="info.mail_recive_flg"
      />
    </transition>
    <transition name="fade">
      <modalWithdrawal
        v-show="isShowWithdrawal"
        @close="closeModalWithdrawal"
      />
    </transition>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import modalChangeMail from "@/components/modalChangeMail.vue";
import modalPassword from "@/components/modalPassword.vue";
import modalChangeTelnumber from "@/components/modalChangeTelnumber.vue";
import modalMagazine from "@/components/modalChangeMagazine.vue";
import modalWithdrawal from "@/components/modalWithdrawal.vue";
import moment from "moment";

export default {
  name: "mypageBasic",
  components: {
    modalChangeMail,
    modalPassword,
    modalChangeTelnumber,
    modalMagazine,
    modalWithdrawal,
  },
  data() {
    return {
      isShowChangeMail: false,
      isShowPassword: false,
      isShowTelnumber: false,
      isShowMagazine: false,
      isShowWithdrawal: false,
      visible: false,
    };
  },
  mounted: function () {
    this.$store.dispatch("memberInfo");
  },
  computed: {
    ...mapGetters({
      info: "getMemberInfo",
      pass: "getPass",
    }),
  },
  methods: {
    closeModalMail() {
      this.isShowChangeMail = false;
    },
    closeModalPassword() {
      this.isShowPassword = false;
    },
    closeModalTelnumber() {
      this.isShowTelnumber = false;
    },
    closeMagazine() {
      this.isShowMagazine = false;
    },
    closeModalWithdrawal() {
      this.isShowWithdrawal = false;
    },
    moment: function (date) {
      return moment(date).format("YYYY/MM/DD");
    },
    eyeClick: function () {
      this.visible = !this.visible;
    },
  },
  beforeUnmount: function () {
    this.$clearAllIntervals();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input {
  border: 0;
  font-weight: 600;
  font-size: 1rem;
  width: 8rem;
}
</style>
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_mypage.css" scoped></style>
<style lang="css" scoped>
@import url("https://use.fontawesome.com/releases/v5.6.1/css/all.css");
</style>
