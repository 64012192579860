<template>
  <div>
    <div class="modal_inner modal_inner--01">
      <div class="content03 content03--modal">
        <h2>メールマガジン設定</h2>
        <div class="wrap">
          <form action="">
            <div class="radio_box">
              <p class="txt01">メールマガジンを受け取る</p>
              <div class="radio">
                <input
                  id="yes"
                  type="radio"
                  value="1"
                  name="mailmagazine"
                  v-model="user.magazine"
                />
                <label for="yes">はい</label>
                <input
                  id="no"
                  type="radio"
                  value="0"
                  name="mailmagazine"
                  v-model="user.magazine"
                />
                <label for="no">いいえ</label>
              </div>
              <p class="txt02">
                ※info@mog.comからのメールを受け取れるよう<br />迷惑メールフィルターのご確認をお願いします。
              </p>
            </div>
            <p class="submit">
              <input
                type="submit"
                value="変更する"
                v-on:click.prevent="changePass()"
              />
            </p>
          </form>
          <button class="btn_modal_close" @click="$emit('close')">
            <img
              src="../assets/images/parts/ico_close.svg"
              width=""
              height=""
              alt="閉じる"
            />閉じる
          </button>
        </div>
        <button class="btn_modal_close_right" @click="$emit('close')">
          <img
            src="../assets/images/parts/ico_close.svg"
            width=""
            height=""
            alt="閉じる"
          />
        </button>
      </div>
    </div>
    <div class="modal_outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
import Valid from "@/mixins/validate";
export default {
  name: "modalPassword",
  props: ["magazineStatus"],
  data: function () {
    return {
      user: {
        magazine: this.magazineStatus,
      },
      valid: {},
      result: {},
    };
  },
  mixins: [Valid],
  methods: {
    changePass() {
      this.$store
        .dispatch("changeMailmagazine", this.user.magazine)
        .then((response) => {
          if (response.data.result) {
            alert("情報を変更しました");
            this.$emit("close");
          } else {
            alert("エラーが発生しました");
          }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span.note {
  font-size: 12px;
  color: rgb(255, 0, 0);
}
</style>
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_form_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_mypage.css" scoped></style>
