<template>
  <div>
    <div class="modal_inner modal_inner--01">
      <div class="content03 content03--modal">
        <h2>退会</h2>
        <div class="wrap">
          <form action="">
            <dl>
              <dt>メールアドレス</dt>
              <dd>
                <input
                  type="text"
                  class="mail"
                  id="mail"
                  v-if="!isForm"
                  v-model="user.mail"
                /><br />
                <span v-if="valid.mail" class="note"
                  >メールアドレスの形式で入力してください</span
                >
                <span class="confirm" v-if="isForm">{{ user.mail }}</span>
              </dd>
            </dl>
            <dl>
              <dt>パスワード</dt>
              <dd>
                <input
                  type="text"
                  class="pass"
                  id="pass"
                  v-if="!isForm"
                  v-model="user.pass"
                /><br />
                <span v-if="valid.pass" class="note"
                  >パスワードは半角英数字8文字以上で入力してください</span
                >
                <span class="confirm" v-if="isForm">{{ user.pass }}</span>
              </dd>
            </dl>
            <p class="note" v-if="isForm">
              ※退会するとデータは削除されます。<br />また二度とログインできなくなります。<br />本当に退会しますか？
            </p>
            <p v-if="!isForm" class="submit">
              <input type="submit" value="確認" v-on:click.prevent="next()" />
            </p>
            <p v-if="isForm" class="submit">
              <input
                type="submit"
                value="キャンセル"
                v-on:click.prevent="cancel()"
              /><input
                type="submit"
                value="退会する"
                v-on:click.prevent="withDrawal(user)"
              />
            </p>
            <p v-if="message.flg" class="note">{{ message.val }}</p>
          </form>
        </div>
        <button class="btn_modal_close_right" @click="$emit('close')">
          <img
            src="../assets/images/parts/ico_close.svg"
            width=""
            height=""
            alt="閉じる"
          />
        </button>
      </div>
    </div>
    <div class="modal_outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
import Valid from "@/mixins/validate";
export default {
  name: "modalWithdrawal",
  data: function () {
    return {
      user: {},
      valid: {},
      isForm: false,
      message: {},
    };
  },
  mixins: [Valid],
  methods: {
    next() {
      if (!this.isInValidEmail) {
        this.valid.mail = true;
      } else {
        this.valid.mail = false;
      }
      if (!this.isInValidPass) {
        this.valid.pass = true;
      } else {
        this.valid.pass = false;
      }
      if (this.isInValidEmail && this.isInValidPass) {
        this.isForm = true;
      }
    },
    cancel() {
      this.valid = {};
      this.user = {};
      this.message = {};
      this.isForm = false;
    },
    withDrawal(val) {
      this.$store.dispatch("withDrawal", val).then((response) => {
        if (response.data.result) {
          this.$store.commit("RESET_VUEX_STATE");
          alert("退会処理が完了しました。");
          this.$router.go({
            path: this.$router.currentRoute.path,
            force: true,
          });
        } else {
          this.message.val = response.data.message;
          this.message.flg = true;
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span.confirm {
  font-size: 20px;
}
</style>
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_form_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_mypage.css" scoped></style>
